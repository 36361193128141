/* Global.css */

/* Reset und Basis-Stile */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #f4f4f9;
    color: #333;
    line-height: 1.6;
  }

  main {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
  }
  
  .btn {
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    display: inline-block;
    text-align: center;
  }
  
  .btn-submit {
    background-color: #4a148c;
    color: #fff;
  }
  
  .btn-submit:hover {
    background-color: #6a1b9a;
  }
  
  .btn-reset {
    background-color: #e53935;
    color: #fff;
  }
  
  .btn-reset:hover {
    background-color: #c62828;
  }