/* Login.css */

.login-main {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  background-color: #f0f2f5;
}

.login-container {
  width: 100%;
  max-width: 400px;
  padding: 2rem;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.login-container h2 {
  color: #4a148c;
  text-align: center;
  margin-bottom: 1.5rem;
}

.error-message {
  color: #e53935;
  background-color: #ffebee;
  padding: 0.75rem;
  border: 1px solid #e57373;
  border-radius: 4px;
  margin-bottom: 1rem;
  text-align: center;
}

.login-form .form-group {
  margin-bottom: 1rem;
}

.login-form .form-group label {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: #4a148c;
}

.login-form .form-group .input-icon {
  margin-right: 0.5rem;
  color: #4a148c;
}

.login-form .form-group input {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.login-form .form-group input:focus {
  border-color: #6a1b9a;
  outline: none;
  box-shadow: 0 0 5px rgba(106, 27, 154, 0.5);
}

.button-group {
  display: flex;
  justify-content: center;
}

.btn {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.btn-submit {
  background-color: #4a148c;
  color: #fff;
}

.btn-submit:hover {
  background-color: #6a1b9a;
}

@media (max-width: 480px) {
  .login-container {
      padding: 1.5rem;
  }

  .login-form .form-group label {
      flex-direction: column;
      align-items: flex-start;
  }

  .login-form .form-group .input-icon {
      margin-bottom: 0.5rem;
  }
}
