/* Modal.css */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  max-width: 90%;
  max-height: 90%;
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #fff;
  color: #333;
  border: none;
  font-size: 1.5rem;
  border-radius: 50%;
  cursor: pointer;
}

.modal-close:hover {
  background-color: #ddd;
}

.modal-image {
  max-width: 100%;
  max-height: 100%;
  border-radius: 8px;
}

@media (max-width: 768px) {
  .modal-image {
    max-width: 90vw;
    max-height: 90vh;
  }

  .modal-content {
    max-width: 90%;
  }
}
