/* SearchForm.css */

.search-bar {
  display: flex;
  align-items: stretch; /* Elemente strecken, um gleiche Höhe zu erreichen */
  gap: 1rem;
}

.search-filter-section {
  margin-top: 2rem;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 1rem;
}

.search-bar input {
  flex: 1;
  padding: 0.75rem 1rem;
  height: 100%; /* Höhe auf 100% setzen */
  border: 2px solid #4a148c;
  border-radius: 4px;
  font-size: 1rem;
}

.filters {
  background-color: #fff;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 1rem;

}

.filters h2 {
  margin-bottom: 1rem;
  color: #4a148c;
}

.filter-group {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  border: 1px solid #ccc;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 4px;
}

.filter-group legend {
  font-weight: bold;
  color: #4a148c;
  padding: 0 0.5rem;
}


.checkbox-item {
  display: flex;
  gap: 0.5rem;
  margin-top: 15px; /* Passt die Position der Checkbox leicht nach unten an */
  margin-bottom: 0px; /* Passt die Position der Checkbox leicht nach unten an */

  padding: 0.5rem 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  padding-left: 1rem;
}

.checkbox-item label {
  font-weight: bold;
  margin: 0;
  color: #4a148c;
}

.checkbox-item input[type='checkbox'] {
  width: 18px;
  height: 18px;
  margin: 0; /* Entfernt jeglichen Abstand um die Checkbox */
  margin-right: 0.5rem;
}


.filter-item {
  flex: 1 1 200px;
  display: flex;
  flex-direction: column;
}

.filter-item label {
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.filter-item select,
.filter-item input[type='number'],
.filter-item input[type='text'],
.filter-item input[type='date'] {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.filter-buttons {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
}

.filter-buttons .btn {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.btn-submit {
  background-color: #4a148c;
  color: #fff;
}

.btn-submit:hover {
  background-color: #6a1b9a;
}

.btn-reset {
  background-color: #e53935;
  color: #fff;
}

.btn-reset:hover {
  background-color: #c62828;
}

.toggle-filter-button {
  padding: 0.75rem 1.5rem;
  background-color: #4a148c;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.toggle-filter-button:hover {
  background-color: #6a1b9a;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .search-bar {
    flex-direction: column;
    gap: 0.5rem;
  }

  .search-bar input {
    width: 100%;
  }

  .toggle-filter-button {
    width: 100%; /* Button unter Suchfeld */
  }
}