/* Footer.css */

footer {
  background-color: #4a148c;
  color: #fff;
  text-align: center;
  padding: 1rem 0;
  margin-top: 3rem;
  position: relative;
  width: 100%;
}

