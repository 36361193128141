/* Sortierkontrollen */
.sort-controls {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  padding: 0.75rem;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.sort-controls label {
  font-weight: bold;
  color: #4a148c;
}

.sort-controls select {
  flex: 1;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  font-size: 1rem;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
}

.sort-controls select:hover {
  border-color: #4a148c;
}

.sort-controls select:focus {
  outline: none;
  border-color: #6a1b9a;
  box-shadow: 0 0 5px rgba(106, 27, 154, 0.3);
}

.sort-controls button {
  padding: 0.5rem 1rem;
  background-color: #4a148c;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.2s ease;
}

.sort-controls button:hover {
  background-color: #6a1b9a;
}

.sort-controls button:active {
  background-color: #38006b;
}

.sort-controls button:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(106, 27, 154, 0.3);
}

/* Mobile Styles */
@media (max-width: 768px) {
  .sort-controls {
    flex-direction: column;
    align-items: stretch;
    padding: 1rem;
  }

  .sort-controls select,
  .sort-controls button {
    width: 100%;
  }
}
