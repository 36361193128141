/* SteinListe.css */

/* Steine-Listen-Abschnitt */
.stein-liste {
  margin-top: 2rem;
}

.stein-liste h2 {
  margin-bottom: 1rem;
  color: #4a148c;
}

.stein-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1.5rem;
}

.stein-card {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.stein-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
}

.stein-card img {
  width: 100%;
  height: auto;
  object-fit: cover;
  cursor: pointer;
}

.image-placeholder {
  width: 100%;
  height: 150px;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
  font-style: italic;
  border-bottom: 1px solid #ddd;
}

.stein-card h3 {
  padding: 0.6rem;
  background-color: #4a148c;
  color: #fff;
  font-size: 1.1rem;
  word-wrap: break-word;
  overflow-wrap: break-word;
  line-height: 1.3;
  min-height: 2.6rem;
}

.stein-card p {
  padding: 0 0.75rem;
  margin-bottom: 0.3rem;
}

.button-group {
  display: flex;
  gap: 0.5rem;
  padding: 0.3rem 0.5rem 0.5rem 0.5rem;
  margin-top: 0.2rem;
}

.button-group button {
  flex: 1;
  padding: 0.4rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 0.9rem;
}

.bearbeiten-button {
  background-color: #1976d2;
  color: #fff;
}

.bearbeiten-button:hover {
  background-color: #1565c0;
}

.loeschen-button {
  background-color: #e53935;
  color: #fff;
}

.loeschen-button:hover {
  background-color: #c62828;
}

.btn-add {
  display: inline-block;
  background-color: #4a148c;
  color: #fff;
  padding: 0.75rem 1.5rem;
  margin-bottom: 1rem;
  text-decoration: none;
  border-radius: 4px;
  font-weight: bold;
}

.btn-add:hover {
  background-color: #6a1b9a;
}

/* Optional: Margin-Top hinzufügen, um Abstand zum vorherigen Element zu schaffen */
.btn-add {
  margin-top: 1rem;
}

.section-divider {
  border: none;
  height: 1px;
  background-color: #ddd;
  margin: 2rem 0;
}

/* Details in der Steinkarte */
.stein-details {
  padding: 0.5rem; /* Weniger Innenabstand */
}

.stein-name {
  font-size: 1.1rem;
  color: #4a148c;
  margin-bottom: 0.3rem; /* Weniger Abstand zum nächsten Element */
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.stein-property {
  display: flex;
  justify-content: space-between;
  margin: 0 0 0.15rem 0; /* Reduzierter Abstand zwischen den Eigenschaften */
  color: #333;
  line-height: 1.3;
}

.property-label {
  font-weight: bold;
  color: #6a1b9a;
  margin-right: 0.5rem;
}

.property-value {
  color: #555;
}

/* Status-spezifische Stile für Steinkarten */
.stein-card.status-potenzieller-kauf h3 {
  background-color: #ff9800; /* Orange für potenziellen Kauf */
}

.stein-card.status-gekauft h3 {
  background-color: #4caf50; /* Grün für gekauft */
}

.stein-card.status-verkauft h3 {
  background-color: #e53935; /* Rot für verkauft */
}

.stein-card.status-noch-keinen-kontakt h3 {
  background-color: #9c27b0; /* Lila für noch keinen Kontakt */
}

.stein-card.status-unbekannt h3 {
  background-color: #757575; /* Grau für unbekannten Status */
}

/* Status-Filter-Schnellzugriff */
.status-filter-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin: 1rem 0;
}

.status-filter-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  color: white;
  transition: opacity 0.3s ease, transform 0.2s ease;
}

.status-filter-button:hover {
  opacity: 0.9;
  transform: translateY(-2px);
}

.status-filter-button.active {
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px currentColor;
}

.status-filter-button.all {
  background-color: #757575;
}

.status-filter-button.potenzieller-kauf {
  background-color: #ff9800;
}

.status-filter-button.gekauft {
  background-color: #4caf50;
}

.status-filter-button.verkauft {
  background-color: #e53935;
}

.status-filter-button.noch-keinen-kontakt {
  background-color: #9c27b0;
}

/* Responsive Design */
@media (max-width: 768px) {
  .stein-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .stein-card {
    margin: 0 auto;
    width: 90%;
  }
}

/* Sortiersteuerung */
.sort-controls {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.75rem 1rem;
  background-color: #f5f5f5;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.sort-controls label {
  font-weight: bold;
  color: #555;
  white-space: nowrap;
}

.sort-controls select {
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  flex-grow: 1;
  min-width: 150px;
}

.sort-controls button {
  padding: 0.5rem 1rem;
  background-color: #4a148c;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.sort-controls button:hover {
  background-color: #6a1b9a;
}

.sort-controls button::after {
  content: '';
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

.sort-controls button.asc::after {
  border-bottom: 5px solid white;
}

.sort-controls button.desc::after {
  border-top: 5px solid white;
}

/* Steine-Header mit Anzahl und Hinzufügen-Button */
.stones-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.stones-count {
  font-size: 0.9rem;
  color: #666;
  background-color: #f5f5f5;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.stones-count p {
  margin: 0;
}

/* Fehlermeldung */
.error-message {
  background-color: #ffebee;
  border: 1px solid #ffcdd2;
  border-radius: 4px;
  padding: 1rem;
  margin: 1rem 0;
  text-align: center;
  color: #c62828;
}

.error-message p {
  margin-bottom: 1rem;
}

.btn-retry {
  background-color: #c62828;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-retry:hover {
  background-color: #b71c1c;
}

/* Paginierung */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
  gap: 1rem;
}

.pagination-button {
  background-color: #4a148c;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.pagination-button:hover:not(:disabled) {
  background-color: #6a1b9a;
  transform: translateY(-2px);
}

.pagination-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination-info {
  font-weight: bold;
  color: #4a148c;
  padding: 0.5rem 1rem;
  background-color: #f5f5f5;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* Responsive Design für Paginierung */
@media (max-width: 768px) {
  .pagination {
    flex-direction: column;
    gap: 0.5rem;
  }
}

/* Einstellung für Steine pro Seite */
.items-per-page {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
  padding: 10px;
}

.items-per-page label {
  margin-right: 10px;
  font-weight: 500;
}

.items-per-page select {
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
}

@media (max-width: 768px) {
  .items-per-page {
    justify-content: center;
  }
}
