/* UserManagement.css */

.user-management {
    max-width: 800px;
    margin: 2rem auto;
    padding: 0 1rem;
  }
  
  .user-management h2 {
    color: #4a148c;
    margin-bottom: 1.5rem;
  }
  
  /* Rechte der Benutzerrollen */
  .role-rights {
    background-color: #f9f9f9;
    padding: 1rem;
    border-radius: 8px;
    margin-bottom: 2rem;
  }
  
  .role-rights h3 {
    color: #4a148c;
    margin-bottom: 1rem;
  }
  
  .role-rights ul {
    list-style-type: disc;
    padding-left: 1.5rem;
  }
  
  .role-rights li {
    margin-bottom: 0.5rem;
  }
  
  /* Formular */
  .user-form-section {
    margin-bottom: 2rem;
  }
  
  .user-form {
    background-color: #fff;
    padding: 1.5rem;
    border-radius: 8px;
  }
  
  .user-form .form-group {
    margin-bottom: 1rem;
  }
  
  .user-form .form-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  .user-form .form-group input,
  .user-form .form-group select {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .button-group {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .btn {
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
  }
  
  .btn-submit {
    background-color: #4a148c;
    color: #fff;
  }
  
  .btn-submit:hover {
    background-color: #6a1b9a;
  }
  
  .btn-cancel {
    background-color: #e0e0e0;
    color: #333;
  }
  
  .btn-cancel:hover {
    background-color: #bdbdbd;
  }
  
  /* Benutzerliste */
  .user-list-section h3 {
    color: #4a148c;
    margin-bottom: 1rem;
  }
  
  .user-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .user-table th,
  .user-table td {
    padding: 0.75rem;
    text-align: left;
    border-bottom: 1px solid #ccc;
  }
  
  .user-table th {
    background-color: #f5f5f5;
    font-weight: bold;
  }
  
  .user-table td {
    vertical-align: middle;
  }
  
  .btn-icon {
    background: none;
    border: none;
    cursor: pointer;
    color: #4a148c;
    font-size: 1.1rem;
    margin-right: 0.5rem;
  }
  
  .btn-icon:hover {
    color: #6a1b9a;
  }
  
  .btn-icon:focus {
    outline: none;
  }
  
  @media (max-width: 600px) {
    .button-group {
      flex-direction: column;
    }
  
    .btn-icon {
      margin-bottom: 0.5rem;
    }
  }
  