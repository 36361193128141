/* SteinDetail.css */

/* Container for stone details */
.stein-detail {
  max-width: 800px;
  margin: 2rem auto;
  padding: 0 1rem;
}

.stein-detail h2 {
  color: #4a148c;
  margin-bottom: 1.5rem;
}

/* Detail image */
.detail-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
  border-radius: 8px;
}

/* Stein information */
.stein-info {
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
}

.info-section {
  margin-bottom: 1.5rem;
}

.info-section h3 {
  margin-bottom: 1rem;
  color: #4a148c;
  border-bottom: 1px solid #ddd;
  padding-bottom: 0.5rem;
}

.stein-info p {
  margin-bottom: 10px;
  color: #4d4d4d;
}

.stein-info strong {
  color: #4a148c;
}

/* Notizen styling */
.notizen-text {
  white-space: pre-wrap;
  background-color: #f9f9f9;
  padding: 1rem;
  border-radius: 4px;
  line-height: 1.5;
}

/* Button group */
.button-group {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
}

.btn {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.btn-submit {
  background-color: #4a148c;
  color: #fff;
}

.btn-submit:hover {
  background-color: #6a1b9a;
}

.btn-reset {
  background-color: #e53935;
  color: #fff;
}

.btn-reset:hover {
  background-color: #c62828;
}

@media (max-width: 600px) {
  .button-group {
    flex-direction: column;
  }
}
