/* Form.css */

.form-section {
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  max-width: 800px;
  margin: 2rem auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-section h1, .form-section h2 {
  margin-bottom: 1.5rem;
  color: #4a148c;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.form-group .required {
  color: #e53935;
}

.form-group input,
.form-group select,
.form-group textarea,
.form-group input[type="file"] {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-group textarea {
  resize: vertical;
  height: 100px;
}

.checkbox-group {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  padding-left: 1rem;
}

/* Label Styling */
.checkbox-group label {
  font-weight: bold;
  margin: 0;
  color: #4a148c;
}

.new-serien-option {
  color: #6a1b9a; /* Leicht violette Farbe */
  font-weight: bold;
}

/* Checkbox Styling */
.checkbox-group input[type='checkbox'] {
  width: 18px;
  height: 18px;
  margin-right: 0.5rem;
}

.details-fieldset {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 1rem;
}

.details-fieldset legend {
  padding: 0 0.5rem;
  font-weight: bold;
  color: #4a148c;
}

.button-group {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
}

.btn {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.btn-submit {
  background-color: #4a148c;
  color: #fff;
}

.btn-submit:hover {
  background-color: #6a1b9a;
}

.btn-cancel {
  background-color: #e0e0e0;
  color: #333;
}

.btn-cancel:hover {
  background-color: #bdbdbd;
}
