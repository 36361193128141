/* Header.css */
header {
  background-color: #4a148c;
  color: #fff;
  padding: 1rem;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.navbar-brand h1 {
  font-size: 1.5rem;
  text-align: center;
}

.navbar-links {
  display: flex;
  align-items: center;
}

.nav-link:hover {
  text-decoration: underline;
}

.nav-link {
  color: #fff;
  margin-left: 1rem;
  text-decoration: none;
  font-size: 1rem;
}

.navbar-links a {
  margin-left: 1rem;
  color: #fff;
  text-decoration: none;
}


.navbar-links a:hover {
  text-decoration: underline;
}

.btn-logout {
  background-color: #e53935;
  color: #fff;
  padding: 0.5rem 1rem;
  margin-left: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.btn-logout:hover {
  background-color: #c62828;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
  }

  .navbar-links {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    margin-top: 1rem;
  }

  .navbar-links a,
  .btn-logout {
    margin-left: 0;
    margin-bottom: 1rem;
    text-align: left;
    width: 100%;
  }
}